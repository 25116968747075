import * as React from 'react';
import { Helmet } from 'react-helmet';
import segment from "../segment";

class FairUsage extends React.Component {

    componentDidMount() {
        segment.page('Fair Usage Policy');
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Fair Usage Policy - Speedweaver</title>
                    <meta name="description" content="" />
                    <meta property="og:image" content="https://speedweaver.co.uk/og.png" />
                </Helmet>

                <header className="container short">
                    <div>
                        <h1>Fair Usage Policy</h1>
                        <p>Last Updated: December 2020</p>
                    </div>
                </header>

                <main className="legal container">
                    <section>
                        <p>This document is the Fair Usage Policy for <a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a> (the platform), it relates to all of our services and computer systems.</p>
                        <p><a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a> is operated by IDX Digital Limited, a company incorporated in the Isle of Man with the company number 133858C and whose registered office is First Floor, Island House, Isle of Man Business Park, Douglas, Isle of Man, IM2 2QZ.</p>
                        <p>This policy will apply to you when you register for an account on <a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a> and it is part of our agreement with you regarding our services.</p>
                    </section>
                    <section>
                        <h2>Summary</h2>
                        <p><a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a> is intended to provide manual and automated ECU tuning solutions to the average tuning enthusiast or business. In order to maintain our service to our users and to keep our user costs down, we estimate the most proficient user would tune approximately 8 vehicles per 24hr period. We estimate that the busiest tuning business will tune approximately 8 cars per day. As such our pricing and usage limits have been built to reflect what we can reasonably expect our customers to need when they are tuning cars.</p>
                        <p><a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a> is <strong>not</strong> intended for use by resellers, file services or distributors.</p>
                    </section>
                    <section>
                        <h2>Support Tickets</h2>
                        <p>The intended uses of our Support Tickets are:</p>
                        <ul>
                            <li>For users to ask general questions regarding <a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a></li>
                            <li>For users to report bugs or software issues on our system</li>
                        </ul>
                        <p><a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a> does not operate a “File Service” and our Support Tickets are not to be used to request that a member of our team manually tunes your file.</p>
                        <p>Support is typically provided free-of-charge as part of the services we offer. To ensure that all customers have equal access to support, we may restrict or suspend access to support for any customer that is consuming more support time, or logging more support issues than a typical customer.</p>
                    </section>
                    <section>
                        <h2>Excessive Use</h2>
                        <p>To prevent a negative effect of excessive use of compute resources, on your user experience or that of others, we monitor our resources continuously. We may, in times of high demand, limit the number of projects you can create in a given time period in order to protect our systems and other users.</p>
                        <p>If we notice that you are attempting to create excessively more projects than other users on the same plan as you, we may restrict or stop your access to the platform.  Before we do this, or immediately after doing so, we’ll always contact you to discuss possible solutions. You must ensure your contact information in your account is always kept up to date.</p>
                    </section>
                    <section>
                        <h2>"Unlimited" Automated Tuning Projects</h2>
                        <p>We continuously monitor the number of Automated Tuning Projects created by users on our Pro subscription plan. In order to protect our systems from heavy load, and to provide a fair and consistent experience to all users, we may limit your access to our Automated Tuning Tools if you use them excessively more than other users on the same plan.</p>
                        <p>Generally, users who create less than 150 automated projects per month will not be monitored and will be unaffected, making the Pro subscription effectively unlimited for the vast majority of users.</p>
                    </section>
                    <section>
                        <h2>Availability in Extreme Cases</h2>
                        <p>We aim to keep <a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a> online and available at all times. However, in some extreme cases we may need to take the platform down for urgent maintenance or to protect our computer systems from attack.</p>
                        <p>In the event that we believe your computer has been compromised or is attempting to cause damage to our systems, we may stop your access to our services, or temporarily block your access to them.</p>
                        <p>In some cases, even without an attack or breach, if your use of <a href="https://speedweaver.co.uk/">Speedweaver.co.uk</a> impacts other users or generates costs to us which we deem outside “normal usage”, we may restrict your access to the platform. Before we do this, or immediately after doing so, we’ll always attempt to contact you to discuss possible solutions.</p>
                    </section>
                </main>
            </>
        );
    }
}

export default FairUsage;
