import * as React from 'react';
import { Helmet } from 'react-helmet';
import segment from "../segment";

import CallToAction from "./components/CallToAction";
import axios from "axios";
import moment from "moment";

class Article extends React.Component {

    state = {

    };

    componentDidMount() {
        segment.page('Home');

        let slug = this.props.match.params.slug;
        axios.get(`https://resources.speedweaver.co.uk/ghost/api/v3/content/posts/slug/${slug}?key=4c3c85b0f93b8c563fe3f70176&include=tags&limit=all`).then((result) => {
            if(!result.data.posts[0]) window.location.href = "/";
            let post = result.data.posts[0];
            this.setState({ ...post })
        }).catch(() => {
            window.location.href = "/";
        });
    }

    render() {
        if(this.state.id){
            return (
                <>
                    <Helmet>
                        <title>{this.state.title} - Speedweaver</title>
                        <meta name="description" content={this.state.custom_excerpt} />
                        <meta property="og:title" content={this.state.title} />
                        <meta property="og:description" content={this.state.custom_excerpt} />
                        <meta property="og:image" content={this.state.featured_image} />
                    </Helmet>

                    <div className="article">
                        <header>
                            <p className="tag">{this.state.tags[0].name}</p>
                            <h1>{this.state.title}</h1>
                            {this.state.custom_excerpt && <p className="subtitle">{this.state.custom_excerpt}</p>}
                            <p className="date">{moment(this.state.published_at).format("D MMM YYYY")}</p>
                        </header>

                        <div dangerouslySetInnerHTML={{__html: this.state.html}} />
                    </div>

                    <CallToAction />
                </>
            );
        } else {
            return false;
        }
    }
}

export default Article;
