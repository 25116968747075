import * as React from 'react';
import { Helmet } from 'react-helmet';
import segment from "../segment";

import CreateAccount from "./components/CreateAccount";
import {NavLink} from "react-router-dom";

class Pricing extends React.Component {

    componentDidMount() {
        segment.page('Pricing');
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Pricing - Speedweaver</title>
                    <meta name="description" content="" />
                    <meta property="og:image" content="https://speedweaver.co.uk/og.png" />
                </Helmet>

                <main>
                    <CreateAccount {...this.props} />

                    <div className="container">
                        <div className="faqs">
                            <div>
                                <h3>What tools can be used?</h3>
                                <p>Speedweaver will work with any master tool. We're currently working to implement support for slave tools also.</p>
                            </div>
                            <div>
                                <h3>What payment methods can I use?</h3>
                                <p>We use Stripe as our payment processor, this means we support most major debit and credit cards. We also accept payment via PayPal.</p>
                            </div>
                            <div>
                                <h3>What about taxes?</h3>
                                <p>Taxes are automatically calculated based on your location and our tax obligations in your region. All of our listed prices are exclusive of taxes such as VAT.</p>
                            </div>
                            <div>
                                <h3>How do subscription cancellations work?</h3>
                                <p>You can cancel your Pro Subscription at any time, you will still have unlimited access to automated tuning until the end of that month’s billing period.</p>
                            </div>
                            <div>
                                <h3>Are there any usage limits?</h3>
                                <p>We have a fair usage policy of up to 150 automated tuning projects per month, which effectively makes it unlimited for most users.</p>
                            </div>
                            <div>
                                <h3>What ECU families are supported?</h3>
                                <p>We support a wide range of ECU families and our engineering team is hard at work adding more families to the system. You can view the full list <NavLink to="/compatibility-list">here</NavLink>.</p>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Pricing;
