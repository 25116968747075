import * as React from 'react';
import { Helmet } from 'react-helmet';
import segment from "../segment";

import CreateAccount from "./components/CreateAccount";

class Diagnostics extends React.Component {

    componentDidMount() {
        segment.page('Diagnostics');
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Monitor, evaluate and refine your tuning - Speedweaver</title>
                    <meta name="description" content="" />
                    <meta property="og:image" content="https://speedweaver.co.uk/og.png" />
                </Helmet>

                <header className="container featurePage">
                    <div>
                        <span>Diagnostics</span>
                        <h1>Monitor, evaluate and refine your tuning.</h1>
                        <div className="appStores">
                            <p>Available on</p>
                            <a href="https://itunes.apple.com/gb/app/speedweaver/id1457564616?mt=8" target="_blank" rel="noopener norefferer">
                                <img src={require('../images/icons/apple.svg')} alt="Apple App Store" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=app.speedweaver.co.uk" target="_blank" rel="noopener norefferer">
                                <img src={require('../images/icons/google.svg')} alt="Google Play Store" />
                            </a>
                        </div>
                    </div>

                    <img src={require('../images/diagnostics/mobile-app-showcase.png')} alt="Vehicle OBD Diagnostics App" />
                </header>

                <main>
                    <section className="dark top">
                        <div className="container feature diagnostics">
                            <div>
                                <div>
                                    <p className="sub">Data Logs</p>
                                    <h2>Capture and review live vehicle data</h2>
                                    <p>As well as Live Monitoring, our mobile app lets you record and save datalogs to the cloud. The built in log viewer gives you precise control over how you review your data with advanced graphing, multi-channel view and playback.</p>
                                    <ul>
                                        <li>Supports WiFi and Bluetooth OBD devices</li>
                                        <li>Record up to 10 PIDs at a time</li>
                                        <li>Log realtime GPS geo-data</li>
                                        <li>Build custom parameter groups</li>
                                        <li>Monitor using Live Dashboard</li>
                                    </ul>
                                </div>
                            </div>
                            <img src={require('../images/diagnostics/data-logs.png')} alt="Vehicle OBD Diagnostic Logs" />
                        </div>

                        <div className="container feature diagnostics">
                            <img src={require('../images/diagnostics/acceleration-tests.png')} alt="Vehicle OBD Acceleration Tests" />
                            <div>
                                <div>
                                    <p className="sub">Acceleration Tests</p>
                                    <h2>Run vehicle acceleration performance tests</h2>
                                    <p>Track the improvements your tunes make to a vehicle by running up to 3 acceleration tests at a time.</p>
                                </div>
                            </div>
                        </div>

                        <div className="container feature diagnostics">
                            <div>
                                <div>
                                    <p className="sub">Diagnostics</p>
                                    <h2>Quickly diagnose issues and areas for improvement</h2>
                                    <p>As well as helping to identify tuning improvements our mobile app can also read and clear DTC fault codes.</p>
                                </div>
                            </div>
                            <img src={require('../images/diagnostics/dtc-lookup.png')} alt="Vehicle OBD Trouble Code Lookup" />
                        </div>
                    </section>

                    <section className="desktopViewer">
                        <div className="container">
                            <div>
                                <p className="sub">Desktop Viewer</p>
                                <h2>Review your data logs on your desktop</h2>
                            </div>

                            <img src={require('../images/diagnostics/desktop-viewer.gif')} alt="Desktop OBD Diagnostic Log Replay" />

                            <div>
                                <div>
                                    <img src={require('../images/diagnostics/multi-channel-views.svg')} alt="Multi-Channel View Icon" />
                                    <h3>Multi-Channel Views</h3>
                                    <p>Group and spread parameters across up to four separate channels for clearer diagnosis.</p>
                                </div>
                                <div>
                                    <img src={require('../images/diagnostics/custom-parameter-settings.svg')} alt="Parameter Settings Icon" />
                                    <h3>Custom Parameter Settings</h3>
                                    <p>Modify parameter layouts, colours, min and max values and visualisation types to suit your needs.</p>
                                </div>
                                <div>
                                    <img src={require('../images/diagnostics/data-log-sharing.svg')} alt="OBD Data Log Sharing Icon" />
                                    <h3>Data Log Sharing</h3>
                                    <p>Easily share your data logs with colleagues and customers to gather feedback or gain insights.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <CreateAccount {...this.props} />
                </main>
            </>
        );
    }
}

export default Diagnostics;