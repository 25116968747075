import * as React from 'react';

class Tools extends React.Component {

    render() {
        return (
            <div className="container masterTools">
                <h2>Compatible With All Master Tools</h2>
                <div className="tools">
                    <a href="https://www.alientech-tools.com/" target="_blank" rel="noopener norefferer">
                        <img src={require("../../images/tools/alientech.png")} alt="Alien Tech" />
                    </a>
                    <a href="https://www.autotuner-tool.com/" target="_blank" rel="noopener norefferer">
                        <img src={require("../../images/tools/autotuner.png")} alt="Autotuner" />
                    </a>
                    <a href="https://www.bflash.eu/" target="_blank" rel="noopener norefferer">
                        <img src={require("../../images/tools/bflash.png")} alt="BFlash" />
                    </a>
                    <a href="https://www.flashtec.ch/index.php/products/cmdflash-obd" target="_blank" rel="noopener norefferer">
                        <img src={require("../../images/tools/cmd.png")} alt="CMD" />
                    </a>
                    <a href="https://www.dimsport.it/en/" target="_blank" rel="noopener norefferer">
                        <img src={require("../../images/tools/dimsport.png")} alt="Dimsport" />
                    </a>
                    <a href="https://www.magicmotorsport.com/" target="_blank" rel="noopener norefferer">
                        <img src={require("../../images/tools/magic.png")} alt="Magic" />
                    </a>
                </div>
            </div>
        );
    }
}

export default Tools;