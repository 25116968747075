import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Popup from "./components/Popup";
import Nav from './components/Nav';
import Footer from './components/Footer';

import Home from './Home';
import AutomatedTuning from "./AutomatedTuning";
import ManualTuning from "./ManualTuning";
import Diagnostics from "./Diagnostics";
import Pricing from './Pricing';
import CompatibilityList from './CompatibilityList';
import PrivacyPolicy from './PrivacyPolicy';
import FairUsage from "./FairUsage";
import News from './News';
import Article from './Article.js';
import Tutorials from './Tutorials';

import ScrollToTop from './components/ScrollToTop';

import '../scss/style.scss';

class App extends React.Component {

    state = {
        showPopup: true
    };

    render() {
        return (
            <>
                <Popup />
                <Nav />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/automated-tuning" component={AutomatedTuning} />
                    <Route exact path="/manual-tuning" component={ManualTuning} />
                    <Route exact path="/diagnostics" component={Diagnostics} />
                    <Route exact path="/pricing" component={Pricing} />
                    <Route exact path="/compatibility-list" component={CompatibilityList} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/fair-usage-policy" component={FairUsage} />
                    <Route exact path="/news" component={News} />
                    <Route exact path="/tutorials" component={Tutorials} />
                    <Route exact path="/:slug" component={Article} />
                    <Redirect to="/" />
                </Switch>
                <Footer />
                <ScrollToTop />
            </>
        )
    }
}

export default App;
