import Analytics from "analytics";
import segmentPlugin from '@analytics/segment';

export default Analytics({
    app: 'speedweaver-web-landingPage',
    plugins: [
        segmentPlugin({
            writeKey: "TFfTZJXmXEelrbetXD0A8p2pkcENJ937" // Prod
            // writeKey: "XhdxeYESdANcq7WNZ7ZW73iAi5GjMdUY" // Dev
        })
    ]
});