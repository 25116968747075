import * as React from 'react';
import { NavLink } from "react-router-dom";
import moment from "moment";
import axios from "axios";

class Posts extends React.Component {

    state = {
        posts: []
    };

    componentDidMount() {
        axios.get("https://resources.speedweaver.co.uk/ghost/api/v3/content/posts?key=4c3c85b0f93b8c563fe3f70176&include=tags&limit=all").then((result) => {
            let posts = result.data.posts;

            posts = posts.map((post) => {
                let tags = post.tags.map((tag) => { return tag.slug });

                return {
                    ...post,
                    tags
                }
            });

            posts = posts.filter((post) => {
                return post.tags.includes(this.props.tag)
            });

            if(this.props.limit) posts = posts.slice(0, this.props.limit);

            this.setState( { posts })
        })
    }

    render() {
        return (
            <div className="container articles">
                {this.state.posts.map((post) => {
                    return (
                        <NavLink to={`/${post.slug}`}>
                            <img src={post.feature_image} alt={post.title} />
                            <h3>{post.title}</h3>
                            <p>{post.custom_excerpt}</p>
                            <p>{moment(post.published_at).format("D MMM YYYY")}</p>
                        </NavLink>
                    )
                })}
            </div>
        )
    }
}

export default Posts;
