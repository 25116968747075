import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie'
import segment from "../../segment";

import SupportStatus from "./SupportStatus";

class Nav extends React.Component {

    state = {
        open: false
    };

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        if(this.state.open === true){
            if(window.innerWidth > 1386){
                this.setState({
                    open: false
                })
            }
        }
    };

    toggleNav = () => {
        this.setState({
            open: !this.state.open
        })
    };

    getStarted = () => {
        segment.track('account_intended', {
            application: "landing-page",
            page: window.location.pathname,
            button: "navigation"
        })
    };

    render() {
        return (
            <>
                <div className="subNav">
                    <div className="wide">
                        {Cookies.get('token') ?
                            <a href="https://dashboard.speedweaver.co.uk/projects" className="login">
                                Dashboard
                                <img src={require('../../images/icons/open.svg')} alt="Open" />
                            </a>
                            :
                            <a href="https://dashboard.speedweaver.co.uk/log-in" className="login">Login<img src={require('../../images/icons/open.svg')} alt="Open" /></a>
                        }
                    </div>
                </div>
                <div className="nav wide">
                    <div>
                        <NavLink to="/" id="logo">
                            <img src={require("../../images/logo.svg")} alt="Speedweaver" />
                        </NavLink>
                        <nav className={this.state.open ? "visible" : null} onClick={this.toggleNav.bind(this)} >
                            <NavLink to="/automated-tuning">Auto Tuning</NavLink>
                            <NavLink to="/manual-tuning">Manual Tuning</NavLink>
                            <NavLink to="/diagnostics">Diagnostics App</NavLink>
                            <NavLink to="/pricing">Pricing <span>New</span></NavLink>
                            <NavLink to="/compatibility-list">Compatibility List</NavLink>
                            <a href="https://dashboard.speedweaver.co.uk/create-account" onClick={this.getStarted}>Get Started</a>
                        </nav>
                    </div>
                    <div>
                        <a href="https://resources.speedweaver.co.uk/">Resources</a>
                        <NavLink to="/compatibility-list">Compatibility List</NavLink>
                        <a href="https://dashboard.speedweaver.co.uk/create-account" className="btn btnPrimary" onClick={this.getStarted}>Get Started</a>
                    </div>
                    <div id="hamburger" onClick={this.toggleNav} className={this.state.open ? "close" : null}><span /></div>
                </div>
            </>
        );
    }
}

export default Nav;
