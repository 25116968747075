import * as React from 'react';

import Plans from "./Plans";

class CreateAccount extends React.Component {
    render() {
        return (
            <div className="container cta success">
                <div className="content">
                    <p className="sub">Create your account</p>
                    <h2>Get Access Now!</h2>
                    <p>Get immediate access with our monthly subscription for access.  Upload tuning files and edit them straight away or purchase a stage template or addon.</p>
                </div>
                <Plans {...this.props} />
            </div>
        );
    }
}

export default CreateAccount;
