import * as React from 'react';
import { NavLink } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Tilt from 'react-tilt';

class LatestUpdateLink extends React.Component {

    state = {
        post: null
    };

    componentDidMount() {
        axios.get("https://resources.speedweaver.co.uk/ghost/api/v3/content/posts?key=4c3c85b0f93b8c563fe3f70176&include=tags&limit=all").then((result) => {
            let posts = result.data.posts;

            posts = posts.map((post) => {
                let tags = post.tags.map((tag) => { return tag.slug });

                return {
                    ...post,
                    tags
                }
            });

            posts = posts.filter((post) => {
                return post.tags.includes("news")
            });

            this.setState({ posts })
        })
    }

    render() {
        if(this.state.posts){
            return (
                <Tilt options={{ max: 0, scale: 1.01 }} className="container latestUpdateLink">
                    <NavLink to={`/${this.state.posts[0].slug}`}>
                        <div>
                            <p>{moment(this.state.posts[0].published_at).fromNow()}</p>
                            <p>{this.state.posts[0].title}</p>
                        </div>
                        <img src={require("../../images/icons/arrow.svg")} alt="Read more..." />
                    </NavLink>
                </Tilt>
            )
        } else {
            return false;
        }
    }
}

export default LatestUpdateLink;