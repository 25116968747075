import * as React from 'react';
import { Helmet } from 'react-helmet';
import segment from "../segment";

import Posts from "./components/Posts";
import CallToAction from "./components/CallToAction";

class Tutorials extends React.Component {

    componentDidMount() {
        segment.page('Home');
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Tutorials - Speedweaver</title>
                    <meta name="description" content="Chiptuning software built for the 21st Century. From fully automated tuning in under 90 seconds, to our advanced browser-based file editor and mobile diagnostics app." />
                    <meta property="og:title" content="Tutorials - Speedweaver" />
                    <meta property="og:description" content="Chiptuning software built for the 21st Century. From fully automated tuning in under 90 seconds, to our advanced browser-based file editor and mobile diagnostics app." />
                    <meta property="og:image" content="https://speedweaver.co.uk/og.png" />
                </Helmet>

                <header className="container ">
                    <h1>Tutorials</h1>
                </header>

                <Posts tag={"academy"} limit={null} />

                <CallToAction />
            </>
        );
    }
}

export default Tutorials;
