import * as React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import segment from "../segment";
import Tilt from 'react-tilt';
import { HashLink } from 'react-router-hash-link';

import queryString from 'query-string';
import Cookies from 'js-cookie';

import { Player } from 'video-react';
import Mousetrap from 'mousetrap';

import CreateAccount from "./components/CreateAccount";
import Tools from "./components/Tools";

class Home extends React.Component {

    state = {
        showVideo: false,
        rolodex: 0,
        addons: [
            "3 Bar MAP Sensor",
            "4 Bar MAP Sensor",
            "Catalyst Monitoring Off",
            "DPF Off",
            "EGR Off",
            "Overrun Crackles",
            "TVA Swirl Off",
            "TVA Off",
            "VMAX Off"
        ]
    };

    componentDidMount() {
        const queries = queryString.parse(this.props.location.search);
        const referrer = {};
        if(queries["_source"]) referrer.source = queries["_source"];
        if(queries["_campaign"]) referrer.campaign = queries["_campaign"];
        if(queries["_theme"]) referrer.theme = queries["_theme"];
        if(queries["_source"] || queries["_campaign"] || queries["_theme"]) Cookies.set("speedweaver-referrer", JSON.stringify(referrer), { domain: ".speedweaver.co.uk"});

        window.addEventListener('load', this.checkVideoAspectRatio);
        window.addEventListener('resize', this.checkVideoAspectRatio);

        Mousetrap.bind("esc", this.hideVideo);

        setInterval(() => { this.next() }, 1500);


        segment.page('Home');
    }

    next = () => {
        let current = this.state.rolodex;
        let rolodex = current + 1;
        if(rolodex > 2) rolodex = 0;
        this.setState({ rolodex });
    };

    componentWillUnmount() {
        window.removeEventListener('load', this.checkVideoAspectRatio);
        window.removeEventListener('resize', this.checkVideoAspectRatio);
    }

    checkVideoAspectRatio = () => {
        const parent = document.getElementsByClassName("video-react")[0];
        const video = document.getElementsByClassName("video-react-video")[0];

        if(parent.getBoundingClientRect().height > video.getBoundingClientRect().height + 2){
            video.classList.add("height");
        } else if(parent.getBoundingClientRect().width > video.getBoundingClientRect().width + 2){
            video.classList.remove("height");
        }
    };

    showVideo = () => {
        this.setState({
            showVideo: true
        })
    };

    hideVideo = () => {
        this.setState({
            showVideo: false
        })
    };

    free = () => {
        segment.track('account_intended', {
            application: "landing-page",
            page: this.props.match.path,
            button: "other-products"
        });
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>ECU Tuning &amp; Remapping Software - Speedweaver</title>
                    <meta name="description" content="Chiptuning software built for the 21st Century. From fully automated tuning in under 90 seconds, to our advanced browser-based file editor and mobile diagnostics app." />
                    <meta property="og:title" content="Automated ECU Tuning, File Editor and Diagnostics Software - Speedweaver" />
                    <meta property="og:description" content="Chiptuning software built for the 21st Century. From fully automated tuning in under 90 seconds, to our advanced browser-based file editor and mobile diagnostics app." />
                    <meta property="og:image" content="https://speedweaver.co.uk/og.png" />
                </Helmet>

                <Player
                    muted={true}
                    autoPlay={true}
                    playsInline={true}
                    loop={true}
                    onLoad
                >
                    <source src={require("../images/promo.mp4")} />
                </Player>

                <header className="hero container">
                    <div className="content">
                        <h1>Custom ECU Tuning & Remapping.</h1>
                        <div className="rolodex">
                            <div className={this.state.rolodex === 0 ? `active` : this.state.rolodex === 1 ? `post` : null}>
                                <img src={require("../images/icons/tick-orange.svg")} alt="Tick" />
                                <p>Stage 1 & 2 tune templates.</p>
                            </div>
                            <div className={this.state.rolodex === 1 ? `active` : this.state.rolodex > 1 ? `post` : null}>
                                <img src={require("../images/icons/tick-orange.svg")} alt="Tick" />
                                <p>Customise in the Editor.</p>
                            </div>
                            <div className={this.state.rolodex === 2 ? `active` : this.state.rolodex === 0 ? `post` : null}>
                                <img src={require("../images/icons/tick-orange.svg")} alt="Tick" />
                                <p>Add addons like overrun crackles.</p>
                            </div>
                        </div>
                        <div>
                            <button onClick={this.showVideo} className="btn btnEmpty btnIcon">
                                <img src={require('../images/icons/play.svg')} alt="Play" />
                                Watch Product Tour
                            </button>
                            <a href="https://dashboard.speedweaver.co.uk/create-account" onClick={this.getStarted} title="Get Started" className="btn btnPrimary">Get Started</a>
                        </div>
                    </div>
                    {this.state.showVideo ?
                        <div className="video">
                            <iframe
                                src="https://www.youtube.com/embed/pHxo5az8rS4?autoplay=1" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                            <span onClick={this.hideVideo} />
                        </div>
                    : null }
                </header>

                <div className="container ">
                    <div className="tools-home">
                        <a href="https://www.alientech-tools.com/" target="_blank" rel="noopener norefferer">
                            <img src={require("../images/tools/alientech.png")} alt="Alien Tech" />
                        </a>
                        <a href="https://www.autotuner-tool.com/" target="_blank" rel="noopener norefferer">
                            <img src={require("../images/tools/autotuner.png")} alt="Autotuner" />
                        </a>
                        <a href="https://www.bflash.eu/" target="_blank" rel="noopener norefferer">
                            <img src={require("../images/tools/bflash.png")} alt="BFlash" />
                        </a>
                        <a href="https://www.flashtec.ch/index.php/products/cmdflash-obd" target="_blank" rel="noopener norefferer">
                            <img src={require("../images/tools/cmd.png")} alt="CMD" />
                        </a>
                        <a href="https://www.dimsport.it/en/" target="_blank" rel="noopener norefferer">
                            <img src={require("../images/tools/dimsport.png")} alt="Dimsport" />
                        </a>
                        <a href="https://www.magicmotorsport.com/" target="_blank" rel="noopener norefferer">
                            <img src={require("../images/tools/magic.png")} alt="Magic" />
                        </a>
                    </div>
                </div>

                <section className="container">
                    <div className="heading">
                        <h2>Tuning file editor with fully defined map packs for €14.95 per month.</h2>
                        <p>* All prices are exclusive of VAT</p>
                    </div>
                    <div className="three">
                        <Tilt options={{ max: 20, scale: 1.03 }}>
                            <HashLink smooth to="/#analyse">
                                <img src={require("../images/generate-map-packs.svg")} alt="Generate Map Packs" />
                                <div>
                                    <p><span>Generate Map Packs.</span> Define 40,000+ maps automatically in a matter of minutes.</p>
                                </div>
                            </HashLink>
                        </Tilt>
                        <Tilt options={{ max: 20, scale: 1.03 }}>
                            <HashLink smooth to="/#tune">
                                <div>
                                    <p><span>Apply Stage Templates.</span> Use pre-built modifications to tune common maps.</p>
                                </div>
                                <img src={require("../images/apply-stage-templates.svg")} alt="Apply Stage Templates" />
                            </HashLink>
                        </Tilt>
                        <Tilt options={{ max: 20, scale: 1.03 }}>
                            <HashLink smooth to="/#edit">
                                <img src={require("../images/customise-in-the-editor.svg")} alt="Customise in the Editor" />
                                <div>
                                    <p><span>Customise in the Editor.</span> Adjust modifications manually with accuracy and precision.</p>
                                </div>
                            </HashLink>
                        </Tilt>
                    </div>
                </section>

                <div className="light" />

                <section className="analyse container" id="analyse">
                    <div className="heading">
                        <span>Analyse</span>
                        <h2>Fully defined map packs help you learn & customise tuning.</h2>
                        <p>Building map packs is time consuming, Speedweaver detects thousands of maps in seconds.</p>
                    </div>

                    <div className="large">
                        <div className="narrow dark">
                            <h3>Intelligent map detection.</h3>
                            <p>Utilising our large repository of map data, Speedweaver uses intelligent detection algorithms to allow you to generate full map packs for your files.</p>
                        </div>
                        <img src={require("../images/hex-viewer.svg")} alt="Hex Viewer" />
                    </div>

                    <div className="small dark">
                        <div>
                            <h4>Fully Defined</h4>
                            <p>Speedweaver detects up to 40,000 maps in compatible files.</p>
                        </div>
                        <div>
                            <h4>Built for Speed</h4>
                            <p>Fully defined map packs, built automatically in under two minutes.</p>
                        </div>
                        <div>
                            <h4>Validated Data</h4>
                            <p>Our map pack data is validated by our experienced engineers.</p>
                        </div>
                    </div>
                </section>

                <section className="container" id="tune">
                    <div className="heading dark">
                        <span>Tune</span>
                        <h2>Stage & patch templates from €5.</h2>
                        <p>Stage Templates and Addons allow you to quickly apply a range of pre-defined modifications to your files.  * All prices are exclusive of VAT</p>
                    </div>

                    <div className="tune dark">
                        <div>
                            <div className="narrow">
                                <h3>Stage 1 and Stage 2 templates help you to tune faster.</h3>
                                <p>Our templates highlight key maps and apply base modifications that you can use as a starting point in your tunes.</p>
                            </div>
                            <img src={require("../images/my-maps.svg")} alt="Speedweaver Editor" />
                        </div>
                        <div>
                            <div>
                                <h3>Use Addons to apply ready-to-use solutions.</h3>
                                <p>Options include:</p>
                                <div className="addons">
                                    {this.state.addons.map((addon) => <p>{addon}</p>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container editor" id="edit">
                    <div className="heading">
                        <span>Edit</span>
                        <h2>Tune files manually, right from your browser.</h2>
                        <p>Speedweaver is a 21st century alternative to WinOLS.</p>
                    </div>

                    <img src={require("../images/editor.png")} alt="Speedweaver Editor" />

                    <div className="features">
                        <div>
                            <img src={require("../images/icons/keyboard-shortcuts.svg")} alt="Keyboard Shortcuts" />
                            <h4>Keyboard Shortcuts</h4>
                            <p>Speed up your workflow with intuitive shortcuts.</p>
                        </div>
                        <div>
                            <img src={require("../images/icons/my-maps.svg")} alt="My Maps" />
                            <h4>My Maps</h4>
                            <p>Find all your favourited and edited maps in one place.</p>
                        </div>
                        <div>
                            <img src={require("../images/icons/smoothing.svg")} alt="Smoothing" />
                            <h4>Smoothing</h4>
                            <p>Refine rough edges in your maps quickly and easily.</p>
                        </div>
                        <div>
                            <img src={require("../images/icons/codename-search.svg")} alt="Codename Search" />
                            <h4>Codename Search</h4>
                            <p>Jump straight to the maps and folders you need.</p>
                        </div>
                        <div>
                            <img src={require("../images/icons/drag-points.svg")} alt="Drag Points" />
                            <h4>Drag Points</h4>
                            <p>Tactile corner and edge points allow for precise edits.</p>
                        </div>
                        <div>
                            <img src={require("../images/icons/relative-change.svg")} alt="Relative Changes" />
                            <h4>Relative Change</h4>
                            <p>Visualise changes by percentage or absolute.</p>
                        </div>
                        <div>
                            <img src={require("../images/icons/axis-editing.svg")} alt="Axis Editing" />
                            <h4>Axis Editing</h4>
                            <p>Modify the X and Y axes for each of your maps.</p>
                        </div>
                        <div>
                            <img src={require("../images/icons/revert-changes.svg")} alt="Revert Changes" />
                            <h4>Revert Changes</h4>
                            <p>Quickly revert maps to their original state.</p>
                        </div>
                    </div>
                </section>

                <section className="container">
                    <div className="heading">
                        <h2>But that's not all.</h2>
                        <p>Speedweaver is packed full of features that help you save time, money and work more effectively.</p>
                    </div>
                    <div className="four">
                        <div>
                            <img src={require("../images/icons/cloud-based.svg")} alt="Unrivaled Quality" />
                            <div>
                                <h4>Unrivaled Quality</h4>
                                <p>Our stage templates provide you with a great foundation, allowing you to tweak performance.</p>
                            </div>
                        </div>
                        <div>
                            <img src={require("../images/icons/software-updates.svg")} alt="Software Updates" />
                            <div>
                                <h4>Platform Updates</h4>
                                <p>We’re continuously releasing platform updates for new families of ECU.</p>
                            </div>
                        </div>
                        <div>
                            <img src={require("../images/icons/access-anywhere.svg")} alt="Access Anywhere" />
                            <div>
                                <h4>Access Anywhere</h4>
                                <p>Log into Speedweaver and tune from any desktop browser.</p>
                            </div>
                        </div>
                        <div>
                            <img src={require("../images/icons/tune-anytime.svg")} alt="Tune Anytime" />
                            <div>
                                <h4>Tune Anytime</h4>
                                <p>24/7 access means you can tune on your terms, not fit into a resellers schedule.</p>
                            </div>
                        </div>
                    </div>
                </section>



                <CreateAccount />
            </>
        );
    }
}

export default Home;
