import * as React from "react";
import { NavLink } from "react-router-dom";

class Popup extends React.Component {

    state = {
        show: false
    };

    componentDidMount() {
        if(localStorage.getItem("popupClosed") !== "1"){
            this.setState({
                show: true
            })
        }
    }

    close = () => {
        localStorage.setItem("popupClosed", "1");
        this.setState({
            show: false
        })
    };

    render(){
        if(this.state.show){
            return (
                <div className="popup">
                    <div>
                        <div>
                            <h2>Announcing Speedweaver Version 5!</h2>
                            <p>We're proud to announce the launch of Speedweaver V5 following over twelve months of redevelopment.</p>
                            <p>Our engineers are hard at work re-populating our database and we are continuously adding new supported vehicles.</p>
                            <p>Please review our <NavLink to="/compatibility-list" onClick={this.close}>compatibility list</NavLink> to see what we support.</p>
                            <button className="btn btnEmpty" onClick={this.close}>Continue...</button>
                        </div>
                        <img src={require('../../images/icons/cross.svg')} alt="Close" onClick={this.close} />
                        <img src={require('../../images/popup.png')} alt="Speedweaver ECU File Editor" />
                    </div>
                    <span onClick={this.close} />
                </div>
            )
        } else {
            return false;
        }
    }
}

export default Popup;
