import * as React from 'react';
import { withRouter } from 'react-router-dom';
import segment from "../../segment";

import axios from 'axios';
import sortObjectsArray from 'sort-objects-array';

class ECUSearch extends React.Component {

    state = {
        loading: true,
        perPage: 50,
        page: 1,
        search: "",
        results: [],
        ecuManufacturer: null,
        ecuProtocol: null,
        searchTerm: ''
    };

    componentDidMount() {
        this.search();
    }

    search = () => {
        axios.get(`https://api.speedweaver.co.uk/api/supported-vehicles?${this.state.search !== "" ? `queryString=${this.state.search}&` : "" }&page=1&resultsPerPage=1000000000`).then((res) => {

            let data = Object.keys(res.data.data).map((key) => { return res.data.data[key]; });

            let unordered = {};

            data.forEach((vehicle) => {

                vehicle.automatedTuning = vehicle.baseWizardsCount > 0 || vehicle.patchWizardsCount > 0;

                if(unordered[vehicle.ecuManufacturer]){
                    unordered[vehicle.ecuManufacturer].push(vehicle);
                } else {
                    unordered[vehicle.ecuManufacturer] = [vehicle];
                }
            });

            Object.keys(unordered).forEach((key) => {
                unordered[key] = sortObjectsArray(unordered[key], 'ecuProtocol');

                const protocols = {};
                unordered[key].forEach((vehicle) => {

                    vehicle = {
                        ...vehicle,
                        vehicleModel: vehicle.vehicleModel.split(" ()")[0]
                    };

                    if(protocols[vehicle.ecuProtocol]){
                        protocols[vehicle.ecuProtocol].push(vehicle);
                    } else {
                        protocols[vehicle.ecuProtocol] = [vehicle];
                    }
                });
                unordered[key] = protocols;
            });

            let results = {};
            Object.keys(unordered).sort().forEach((key) => {
                results[key] = unordered[key];
            });

            let formatted = [];

            const ecuManufacturers = Object.keys(results);
            formatted = ecuManufacturers.map((ecuManufacturer) => {

                let ecuProtocols = Object.keys(results[ecuManufacturer]);
                ecuProtocols = ecuProtocols.map((ecuProtocol) => {

                    let powerTrains = sortObjectsArray(results[ecuManufacturer][ecuProtocol], "vehiclePowerTrain");
                    powerTrains = sortObjectsArray(powerTrains, "vehicleModel");
                    powerTrains = sortObjectsArray(powerTrains, "vehicleMake");

                    return {
                        name: ecuProtocol,
                        powerTrains
                    }
                });

                return {
                    name: ecuManufacturer,
                    ecuProtocols: ecuProtocols
                }
            });

            this.setState({
                loading: false,
                results: formatted
            })
        }).catch((error) => {
            console.log(error);
        })
    };

    setEcuManufacturer = (event) => {
        this.setState({
            page: 1,
            ecuManufacturer: event.target.value === "all" ? null : event.target.value,
            ecuProtocol: null
        })
    };

    setEcuProtocol = (event) => {
        this.setState({
            page: 1,
            ecuProtocol: event.target.value === "all" ? null : event.target.value
        })
    };

    shortenModel = (model) => {

        model = model.split(" (")[0];

        const regex = new RegExp(/\d{4}$/);
        model = model.split(regex)[0].trim();

        return model;
    };

    removeDuplicates = (a) => {
        let seen = {};
        return a.filter(function(item) {
            return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        });
    };

    next = () => {
        window.scrollTo(0, 500);
        this.setState({ page: this.state.page + 1 })
    };

    prev = () => {
        window.scrollTo(0, 500);
        this.setState({ page: this.state.page - 1 < 1 ? 1 : this.state.page - 1 })
    };

    updateSearchTerm = (e) => {
        this.setState({
            searchTerm: e.target.value
        })
    }

    render() {

        let i = 0;
        const start = (this.state.page - 1) * this.state.perPage;
        const end = start + this.state.perPage;

        let filterResults = this.state.results.flatMap(ecu => {
            return ecu.ecuProtocols.flatMap(ecuProtocol => {
                return ecuProtocol.powerTrains
            })
        })

        console.log(filterResults)

        return (
            <>
                {this.state.loading ?
                    <div className="loading">
                        <p>Loading protocols, please wait...</p>
                    </div>
                    :
                    <>
                        <div className="form">
                            <select name="make" value={this.state.ecuManufacturer} onChange={this.setEcuManufacturer}>
                                <option value="all">All ECU Manufacturers</option>
                                {this.state.results.map((ecuManufacturer) => {

                                    let count = 0;
                                    ecuManufacturer.ecuProtocols.forEach((ecuProtocol) => {
                                        count = count + ecuProtocol.powerTrains.length;
                                    });

                                    return <option value={ecuManufacturer.name}>{ecuManufacturer.name} ({count})</option>
                                })}
                            </select>
                            <select name="model" value={this.state.ecuProtocols} onChange={this.setEcuProtocol} disabled={!this.state.ecuManufacturer}>
                                <option value="all">All ECU Protocols</option>

                                {this.state.ecuManufacturer && this.state.results.map((ecuManufacturer) => {
                                    if(this.state.ecuManufacturer === ecuManufacturer.name){

                                        const ecuProtocols = ecuManufacturer.ecuProtocols.map((ecuProtocol) => ({...ecuProtocol, name: this.shortenModel(ecuProtocol.name)}));

                                        const ecuProtocolCounts = ecuProtocols.reduce((acc, val) => {
                                            acc[val.name] = acc[val.name] === undefined ? val.powerTrains.length : acc[val.name] += val.powerTrains.length;
                                            return acc;
                                        }, {});

                                        return Object.entries(ecuProtocolCounts).map(([ecuProtocol, count]) =>
                                            <option value={ecuProtocol}>{ecuProtocol} ({count})</option>
                                        )
                                    } else {
                                        return false;
                                    }
                                })}
                            </select>

                            <input className="searchTerm" type="text" name="searchTerm" placeholder="Search..." value={this.state.searchTerm} onChange={this.updateSearchTerm.bind(this)}/>
                        </div>
                        <div className="results ecu">

                            <div className="head">
                                <p>ECU Protocol</p>
                                <p>Make</p>
                                <p>Model</p>
                                <p>Powertrain</p>
                                <p>Tuning Modes</p>
                            </div>

                            {filterResults.map((result) => {
                                if((!this.state.ecuManufacturer || this.state.ecuManufacturer === result.ecuManufacturer) &&
                                    (!this.state.ecuProtocol || this.state.ecuProtocol === this.shortenModel(result.ecuProtocol)) &&
                                    (!this.state.searchTerm || this.state.searchTerm.length > 0 &&
                                        (
                                            result.vehicleMake.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                                            result.vehicleModel.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                                            result.vehiclePowerTrain.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                                            result.ecuProtocol.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                                            result.ecuManufacturer.toLowerCase().includes(this.state.searchTerm.toLowerCase())
                                        )
                                    )
                                )
                                if(!this.state.ecuManufacturer || this.state.ecuManufacturer === result.ecuManufacturer){
                                    i = i + 1;

                                    if(start <= i && i < end){
                                        return (
                                            <div>
                                                <p>{result.ecuManufacturer} {result.ecuProtocol}</p>
                                                <p>{result.vehicleMake}</p>
                                                <p>{result.vehicleModel}</p>
                                                <p>{result.vehiclePowerTrain}</p>
                                                {result.automatedTuning ?
                                                    <p><img src={require("../../images/icons/lightning.svg")} alt="Automated Tuning Icon" />Automated Tuning &amp; File Editor</p>
                                                    :
                                                    <p><img src={require("../../images/icons/lightning-grey.svg")} alt="File Editor Icon" />File Editor Only</p>
                                                }
                                            </div>
                                        )
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return false
                                }
                            })}
                        </div>
                        <div className="pagination">
                            <button onClick={this.prev} className="btn btnEmpty" disabled={this.state.page === 1}>Prev</button>
                            <button onClick={this.next} className="btn btnEmpty" disabled={i <= end}>Next</button>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default withRouter(ECUSearch);