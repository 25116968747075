import * as React from 'react';
import Tilt from 'react-tilt';

class CallToAction extends React.Component {
    render() {
        return (
            <Tilt options={{ max: 5, scale: 1 }} className="wide callToAction">
                <a href="https://dashboard.speedweaver.co.uk/create-account">
                    <div>
                        <h1>&euro;14.95 per month.</h1>
                        <div className="ticks">
                            <div>
                                <img src={require("../../images/icons/tick.svg")} alt="Tick" />
                                <p>Cancel Anytime</p>
                            </div>
                            <div>
                                <img src={require("../../images/icons/tick.svg")} alt="Tick" />
                                <p>Access Tuning Editor</p>
                            </div>
                            <div>
                                <img src={require("../../images/icons/tick.svg")} alt="Tick" />
                                <p>Start Tuning in Minutes</p>
                            </div>
                        </div>
                    </div>
                    <img src={require("../../images/icons/proceed.svg")} alt="Continue" />
                </a>
            </Tilt>
        );
    }
}

export default CallToAction;
