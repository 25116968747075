import * as React from "react";
import segment from "../../segment";
import Cookies from "js-cookie";

class Plans extends React.Component {

    free = () => {
        segment.track('account_intended', {
            application: "landing-page",
            page: this.props.match.path,
            button: "free-plan"
        });
    };

    pro = () => {
        Cookies.set("pro-intention", true, { domain: ".speedweaver.co.uk",  expires: 0.5});
        segment.track('account_intended', {
            application: "landing-page",
            page: this.props.match.path,
            button: "pro-plan"
        });
    };

    render(){
        return (
            <>
                <div className="plans">
                    <div className="plan">
                        <span>New</span>
                        <h2>Subscription</h2>
                        <p className="price">&euro;14.95 <span>/ month</span></p>

                        <div>
                            <p><img src={require('../../images/icons/tick.svg')} alt="Yes" />Unlimited Manual Projects</p>
                            <p><img src={require('../../images/icons/tick.svg')} alt="Yes" />Unlimited Diagnostics Logs</p>
                            <p><img src={require('../../images/icons/plus.svg')} alt="Plus" /> <strong>&euro;10 per Stage Template</strong></p>
                            <p><img src={require('../../images/icons/plus.svg')} alt="Plus" /> <strong>&euro;5 per Addon</strong></p>

                        </div>

                        <div>
                            <p><img src={require('../../images/icons/tick.svg')} alt="Yes" />Full File Editor Features</p>
                            <p><img src={require('../../images/icons/tick.svg')} alt="Yes" />File Editor Map Definitions</p>
                            <p><img src={require('../../images/icons/tick.svg')} alt="Yes" />Rapid File Processing</p>
                            <p><img src={require('../../images/icons/tick.svg')} alt="Yes" />Project Retention</p>
                        </div>

                        <a href={"https://dashboard.speedweaver.co.uk/create-account"} className="btn btnPrimary" onClick={this.pro}>Subscribe</a>

                        <p className="smallPrint">Billed Monthly &bull; Cancel Anytime</p>
                    </div>
                </div>

                <p className="centred">* All prices are exclusive of VAT</p>
            </>
        )
    }
}

export default Plans;
