import * as React from 'react';
import {Helmet} from "react-helmet";
import segment from "../segment";
import axios from 'axios';
import SortObjectsArray from 'sort-objects-array';

import VehicleSearch from "./components/VehicleSearch";
import ECUSearch from "./components/ECUSearch";
import CreateAccount from "./components/CreateAccount";
import LatestUpdateLink from "./components/LatestUpdateLink";


class CompatibilityList extends React.Component {

    state = {
        mode: 0 ,
        totalModels: 0,
        totalProtocols: 0,
        totalPowerTrains: 0
    };

    async componentDidMount() {
        segment.page('Compatibility List');

        let data = await axios.get("https://api.speedweaver.co.uk/api/supported-vehicles?&page=1&resultsPerPage=1000000000");
        data = data.data.data;
        data = Object.keys(data).map((powerTrain) => { return data[powerTrain] });
        let totalPowerTrains = data.length;
        let families = data.map((powerTrain) => {
            return {
                make: powerTrain.vehicleMake,
                ecu: `${powerTrain.ecuManufacturer} ${powerTrain.ecuProtocol}`,
                logo: powerTrain.logo
            }
        });

        let totalProtocols = families.map((family) => {
            return family.ecu;
        });
        totalProtocols = totalProtocols.reduce((acc, val) => {
            if(!acc.includes(val)) acc.push(val);
            return acc;
        }, []);
        totalProtocols = totalProtocols.length;

        let totalModels = data.map((powerTrain) => {
            return powerTrain.vehicleModel;
        });
        totalModels = totalModels.reduce((acc, val) => {
            if(!acc.includes(val)) acc.push(val);
            return acc;
        }, []);
        totalModels = totalModels.length;

        let mode = 0;

        this.setState({ mode, totalProtocols, totalModels, totalPowerTrains });

    }

    updateMode = (mode) => {
        this.setState({ mode })
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>Compatibility List - Speedweaver</title>
                    <meta name="description" content="" />
                    <meta property="og:image" content="https://speedweaver.co.uk/og.png" />
                </Helmet>

                <LatestUpdateLink />

                <div className="container">
                    <h1>Compatibility</h1>
                    <div className="stats">
                        <div>
                            <h2>{this.state.totalProtocols.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                            <p>Protocols</p>
                        </div>
                        <div>
                            <h2>{this.state.totalModels.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                            <p>Models</p>
                        </div>
                        <div>
                            <h2>{this.state.totalPowerTrains.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                            <p>Powertrains</p>
                        </div>
                    </div>
                </div>

                <main>
                    <div className="container protocolSearch">

                        <div className="switch">
                            <p onClick={this.updateMode.bind(this, 0)} className={this.state.mode === 0 ? "active" : null}>Search by Vehicle</p>
                            <p onClick={this.updateMode.bind(this, 1)} className={this.state.mode === 1 ? "active" : null}>Search by ECU Protocol</p>
                        </div>

                        {!this.state.mode ?
                            <VehicleSearch />
                        :
                            <ECUSearch />
                        }
                    </div>

                    <CreateAccount {...this.props} />
                </main>
            </>
        );
    }
}

export default CompatibilityList;
