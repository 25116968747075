import * as React from 'react';
import { withRouter } from 'react-router-dom';
import segment from "../../segment";

import axios from 'axios';
import sortObjectsArray from 'sort-objects-array';

class VehicleSearch extends React.Component {

    state = {
        loading: true,
        perPage: 50,
        page: 1,
        search: "",
        results: [],
        searchTerm: '',
        filterResults: [],
        make: null
    };

    componentDidMount() {
        this.search();
    }

    search = () => {
        axios.get(`https://api.speedweaver.co.uk/api/supported-vehicles?${this.state.search !== "" ? `queryString=${this.state.search}&` : "" }&page=1&resultsPerPage=1000000000`).then((res) => {

            let data = Object.keys(res.data.data).map((key) => { return res.data.data[key]; });

            let unordered = {};

            data.forEach((vehicle) => {

                vehicle.automatedTuning = vehicle.baseWizardsCount > 0 || vehicle.patchWizardsCount > 0;

                if(unordered[vehicle.vehicleMake]){
                    unordered[vehicle.vehicleMake].push(vehicle);
                } else {
                    unordered[vehicle.vehicleMake] = [vehicle];
                }
            });

            Object.keys(unordered).forEach((key) => {
                unordered[key] = sortObjectsArray(unordered[key], 'vehicleModel');

                const models = {};
                unordered[key].forEach((vehicle) => {

                    vehicle = {
                        ...vehicle,
                        vehicleModel: vehicle.vehicleModel.split(" ()")[0]
                    };

                    if(models[vehicle.vehicleModel]){
                        models[vehicle.vehicleModel].push(vehicle);
                    } else {
                        models[vehicle.vehicleModel] = [vehicle];
                    }
                });
                unordered[key] = models;
            });

            let results = {};
            Object.keys(unordered).sort().forEach((key) => {
                results[key] = unordered[key];
            });

            let formatted = [];

            const makes = Object.keys(results);
            formatted = makes.map((make) => {

                let models = Object.keys(results[make]);
                models = models.map((model) => {
                    return {
                        name: model,
                        powerTrains: sortObjectsArray(results[make][model], "vehiclePowerTrain")
                    }
                });

                return {
                   name: make,
                   models: models
                }
            });

            this.setState({
                loading: false,
                results: formatted
            })
        }).catch((error) => {
            console.log(error);
        })
    };

    setMake = (event) => {
        this.setState({
            page: 1,
            make: event.target.value === "all" ? null : event.target.value,
            model: null
        })
    };

    setModel = (event) => {
        this.setState({
            page: 1,
            model: event.target.value === "all" ? null : event.target.value
        })
    };

    shortenModel = (model) => {

        model = model.split(" (")[0];

        const regex = new RegExp(/\d{4}$/);
        model = model.split(regex)[0].trim();

        return model;
    };

    next = () => {
        window.scrollTo(0, 0);
        this.setState({ page: this.state.page + 1 })
    };

    prev = () => {
        window.scrollTo(0, 0);
        this.setState({ page: this.state.page - 1 < 1 ? 1 : this.state.page - 1 })
    };

    updateSearchTerm = (e) => {
        this.setState({
            searchTerm: e.target.value
        })
    }


    render() {

        let i = 0;
        const start = (this.state.page - 1) * this.state.perPage;
        const end = start + this.state.perPage;

        let filterResults = this.state.results.flatMap(make => {
            return make.models.flatMap(model => {
                return model.powerTrains
            })
        })

        return (
            <>
                {this.state.loading ?
                    <div className="loading">
                        <p>Loading protocols, please wait...</p>
                    </div>
                :
                    <>
                        <div className="form">
                            <select name="make" value={this.state.make} onChange={this.setMake}>
                                <option value="all">All Makes</option>
                                {this.state.results.map((make) => {

                                    let count = 0;
                                    make.models.forEach((model) => {
                                        count = count + model.powerTrains.length;
                                    });

                                    return <option value={make.name}>{make.name} ({count})</option>
                                })}
                            </select>
                            <select name="model" value={this.state.model} onChange={this.setModel} disabled={!this.state.make}>
                                <option value="all">All Models</option>

                                {this.state.make && this.state.results.map((make) => {
                                    if(this.state.make === make.name){

                                        const models = make.models.map((model) => ({...model, name: this.shortenModel(model.name)}));

                                        const modelCounts = models.reduce((acc, val) => {
                                            acc[val.name] = acc[val.name] === undefined ? val.powerTrains.length : acc[val.name] += val.powerTrains.length;
                                            return acc;
                                        }, {});

                                        return Object.entries(modelCounts).map(([model, count]) => {
                                            return <option value={model}>{model} ({count})</option>
                                        })
                                    } else {
                                        return false;
                                    }
                                })}
                            </select>

                            <input className="searchTerm" type="text" name="searchTerm" placeholder="Search..." value={this.state.searchTerm} onChange={this.updateSearchTerm.bind(this)}/>
                        </div>
                        <div className="results">

                            <div className="head">
                                <p>Make</p>
                                <p>Model</p>
                                <p>Powertrain</p>
                                <p>ECU Protocol</p>
                                <p>Tuning Modes</p>
                            </div>

                            {filterResults.map((result) => {
                                if((!this.state.make || this.state.make === result.vehicleMake) &&
                                    (!this.state.model || this.state.model === this.shortenModel(result.vehicleModel)) &&
                                    (!this.state.searchTerm || this.state.searchTerm.length > 0 &&
                                        (
                                            result.vehicleMake.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                                            result.vehicleModel.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                                            result.vehiclePowerTrain.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                                            result.ecuProtocol.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                                            result.ecuManufacturer.toLowerCase().includes(this.state.searchTerm.toLowerCase())
                                        )
                                    )
                                ){
                                    i = i + 1;

                                    if(start <= i && i < end){
                                        return (
                                            <div>
                                                <p>{result.vehicleMake}</p>
                                                <p>{result.vehicleModel}</p>
                                                <p>{result.vehiclePowerTrain}</p>
                                                <p>{result.ecuManufacturer} {result.ecuProtocol}</p>
                                                {result.automatedTuning ?
                                                    <p><img src={require("../../images/icons/lightning.svg")} alt="Automated Tuning Icon" />Automated Tuning &amp; File Editor</p>
                                                :
                                                    <p><img src={require("../../images/icons/lightning-grey.svg")} alt="File Editor Icon" />File Editor Only</p>
                                                }
                                            </div>
                                        )
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return false
                                }
                            })}
                        </div>
                        <div className="pagination">
                            <button onClick={this.prev} className="btn btnEmpty" disabled={this.state.page === 1}>Prev</button>
                            <button onClick={this.next} className="btn btnEmpty" disabled={i <= end}>Next</button>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default withRouter(VehicleSearch);