import * as React from 'react';
import axios from 'axios';

class SupportStatus extends React.Component {

    state = {
        loading: true,
        status: "",
        days: null
    };

    componentDidMount() {
        this.fetchStatus();

        axios.get("https://api.speedweaver.co.uk/api/response-profile/support-summary").then((res) => {
            this.setState({
                days: Object.keys(res.data.days).map((key) => {
                    return {
                        day: key,
                        times: res.data.days[`${key}`][0]
                    }
                })
            })
        }).catch((error) => {
            console.log(error);
        });
    }

    fetchStatus = () => {
        axios.get("https://api.speedweaver.co.uk/api/response-profile/support-time").then((res) => {
            this.setState({
                status: res.data,
                loading: false
            })
        }).catch((error) => {
            console.log(error);
        });

        setTimeout(this.fetchStatus, 60000);
    };

    render() {
        if(!this.state.loading){
            return (
                <div className="systemStatus">
                    <span className={this.state.status === "full" ? "green" : "red"} />
                    <p>Support{this.state.status === "full" ? " Online" : " Offline"}</p>
                    <img src={require('../..//images/icons/i.svg')} alt="Info" />

                    {this.state.days &&
                        <div className="days">
                            {this.state.days.map((day) => {
                                return (
                                    <div>
                                        <p>{day.day}</p>
                                        <p>{day.times[0]}</p>
                                        <p>-</p>
                                        <p>{day.times[1]}</p>
                                        <p>GMT</p>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            );
        } else {
            return false;
        }
    }
}

export default SupportStatus;


