import * as React from 'react';
import { Helmet } from 'react-helmet';
import segment from "../segment";

import CreateAccount from "./components/CreateAccount";

class AutomatedTuning extends React.Component {

    state = {
        step: 1
    };

    componentDidMount() {
        window.addEventListener("scroll", (event) => {
            if(window.scrollY < 500) this.setState({ step: 1 });
            if(501 < window.scrollY && window.scrollY < 1000) this.setState({ step: 2 });
            if(1001 < window.scrollY) this.setState({ step: 3 });
        })

        segment.page('Automated Tuning');
    }

    selectStep = (step) => {
        this.setState({ step })
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>Apply our pre-made tunes to your files in seconds - Speedweaver</title>
                    <meta name="description" content="" />
                    <meta property="og:image" content="https://speedweaver.co.uk/og.png" />
                </Helmet>

                <header className="container featurePage howItWorks">
                    <div>
                        <span>Automated Tuning</span>
                        <h1>Apply our pre-made tunes<br />to your files in seconds</h1>
                        <p>Choose from our range of preloaded modifications from Stage 1 to EGR Off</p>
                    </div>
                    <img src={require('../images/automated-tuning/how-it-works.svg')} alt="Automated Tuning Process" />
                </header>

                <main>
                    <section className="dark automated">
                        <div className="container feature">
                            <div>
                                <div>
                                    <h2>Visualise improvements on an interactive graph</h2>
                                    <p>Each of our Stage Template layers comes with an illustration of the expected performance improvements in terms of both power (hp) and torque (nm)</p>
                                </div>
                            </div>
                            <img src={require('../images/automated-tuning/performance-graphs.svg')} alt="Dyno Graph Lines" />
                        </div>

                    <div className="container feature">
                        <img src={require('../images/automated-tuning/apply-your-tune.svg')} alt="" />
                        <div>
                            <div>
                                <h2>Our engineers are continuously creating new stage templates & addons</h2>
                                <p>Our engineers are always improving our system and you will benefit from new releases of supported ECUs.</p>
                            </div>
                        </div>
                    </div>

                        <div className="container feature">
                            <div>
                                <div>
                                    <h2>Fine tune modifications in the file editor</h2>
                                    <p>Open your tuned project in the Editor and you’ll see all of the edited maps in the ‘My Maps’ tab. You can then tweak the modifications to suit exactly what you need.</p>
                                </div>
                            </div>
                            <img src={require('../images/automated-tuning/fine-tune.svg')} alt="Map Editing Table" />
                        </div>
                    </section>

                    <CreateAccount {...this.props} />
                </main>
            </>
        );
    }
}

export default AutomatedTuning;
