import * as React from 'react';
import { Helmet } from 'react-helmet';
import segment from "../segment";

import CreateAccount from "./components/CreateAccount";

class ManualTuning extends React.Component {

    componentDidMount() {
        segment.page('Manual Tuning');
    }

    state = {
        hero: 0
    };

    toggleHero = (hero) => {
        this.setState({ hero })
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>A powerful file editor with intelligent features - Speedweaver</title>
                    <meta name="description" content="" />
                    <meta property="og:image" content="https://speedweaver.co.uk/og.png" />
                </Helmet>

                <header className="container featurePage">
                    <div>
                        <span>File Editor</span>
                        <h1>A powerful online tuning file editor<br />with intelligent features.</h1>
                        <p>Tune files directly from your browser, with pre-loaded full map definition packs.</p>
                    </div>

                    { this.state.hero === 0 ?
                        <img src={require('../images/manual-tuning/editor.png')} alt="Speedweaver File Editor Screenshot" className="shadow" />
                    : this.state.hero === 1 ?
                        <img src={require('../images/manual-tuning/pre-defined-map-packs.png')} alt="Speedweaver File Editor Screenshot" className="shadow" />
                    : this.state.hero === 2 ?
                        <img src={require('../images/manual-tuning/intuitive-tune-tools.png')} alt="Speedweaver File Editor Screenshot" className="shadow" />
                    :
                        <img src={require('../images/manual-tuning/navigate-using-hex-viewer.png')} alt="Speedweaver File Editor Screenshot" className="shadow" />
                    }

                    <div className="container switch" onMouseLeave={this.toggleHero.bind(this, 0)}>
                        <div onMouseEnter={this.toggleHero.bind(this, 1)} className={this.state.hero === 1 ? "active" : ""}>
                            <h3>Pre Defined Map Packs</h3>
                            <p>Immediately access full pre-defined map packs for any file, no need to define maps yourself.</p>
                        </div>
                        <div onMouseEnter={this.toggleHero.bind(this, 2)} className={this.state.hero === 2 ? "active" : ""}>
                            <h3>Intuitive Tune Tools</h3>
                            <p>Make precise changes to values using our range of intuitive and tactile modification tools.</p>
                        </div>
                        <div onMouseEnter={this.toggleHero.bind(this, 3)} className={this.state.hero === 3 ? "active" : ""}>
                            <h3>Navigate Using Hex Viewer</h3>
                            <p>Browse your file using the familiar look of the 2D hex viewer with 8, 16 and 32 bit modes.</p>
                        </div>
                    </div>
                </header>

                <main>
                    <section className="dark top">
                        <div className="container feature">
                            <div>
                                <div>
                                    <h2>Modify values precisely with advanced tools</h2>
                                    <p>The Gradient Tool introduces a new tactile approach to map editing, giving you the ability to click and drag on a selection to create smooth changes. If you need to make more precise changes then the Formula Bar makes it easy to perform basic and advanced functions on a group of values.</p>
                                </div>
                            </div>
                            <img src={require('../images/manual-tuning/tuning-tools.svg')} alt="Map Editing Screenshot" />
                        </div>

                        <div className="container feature">
                            <img src={require('../images/manual-tuning/hex-viewer.png')} alt="File Hex Viewer" />
                            <div>
                                <div>
                                    <h2>Navigate through files in 8, 16 or 32 Bit</h2>
                                    <p>The Hex Viewer lets you visualise the entire calibration area in a familiar 2D mode, highlighting and intelligently separating different maps and sub-routines.</p>
                                </div>
                            </div>
                        </div>

                        <div className="container feature">
                            <div>
                                <div>
                                    <h2>Quickly find modified and favourited maps</h2>
                                    <p>Speedweaver’s advanced map detection makes it easier than ever to find the maps you’re looking for. Browse by grouped sub-routines or search by codename and map descriptions.</p>
                                </div>
                            </div>
                            <img src={require('../images/manual-tuning/my-maps.svg')} alt="My Maps Interface" />
                        </div>

                        <div className="container feature">
                            <img src={require('../images/manual-tuning/axis-editing.svg')} alt="Axis Editing Interface" />
                            <div>
                                <div>
                                    <h2>Modify standalone and shared axes</h2>
                                    <p>Speedweaver gives you full access to modify any map's X and Y axis, we even take into account axes which are shared across multiple maps.</p>
                                </div>
                            </div>
                        </div>

                        <div className="container feature">
                            <div>
                                <div>
                                    <h2>Visualise data as 2D and 3D interactive representations</h2>
                                    <p>Depending on the type of map or axis you're editing you have access to visualise the data in either an interactive 2D or 3D mode as well as the usual table view.</p>
                                </div>
                            </div>
                            <img src={require('../images/manual-tuning/view-modes.png')} alt="3 Dimensional Map Data" />
                        </div>

                        <div className="container feature">
                            <img src={require('../images/manual-tuning/help-bar.png')} alt="Help Bar" />
                            <div>
                                <div>
                                    <h2>Easily access help and tips from the help bar</h2>
                                    <p>The help bar in the bottom left gives you useful tips on any area of the editor that you hover over, as well as details of keyboard shortcuts and colour codes.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <CreateAccount {...this.props} />
                </main>
            </>
        );
    }
}

export default ManualTuning;
