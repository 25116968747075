import * as React from 'react';
import { NavLink } from "react-router-dom";
import Tools from './Tools';
import segment from "../../segment";
import Mailchimp from 'react-mailchimp-form';

class Footer extends React.Component {

    free = () => {
        segment.track('account_intended', {
            application: "fotter",
            page: this.props.match.path,
            button: "footer-sign-up"
        });
    };


    render() {
        return (
            <>
                <Tools />
                <footer className="container">

                    <div className="links">
                        <div>
                            <h3>Speedweaver</h3>
                            <NavLink to="/file-editor">File Editor</NavLink>
                            <NavLink to="/automated-tuning">Automated Tuning</NavLink>
                            <NavLink to="/diagnostics">Diagnostics</NavLink>
                            <NavLink to="/pricing">Pricing</NavLink>
                            <NavLink to="/compatibility-list">Compatibility List</NavLink>
                        </div>
                        <div>
                            <div>
                                <h3>Legals</h3>
                                <NavLink to="/fair-usage-policy">Fair Usage Policy</NavLink>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                            </div>
                            <div>
                                <h3>Mobile App</h3>
                                <div className="appStores">
                                    <a href="https://itunes.apple.com/gb/app/speedweaver/id1457564616?mt=8" target="_blank" rel="noopener norefferer">
                                        <img src={require('../../images/icons/apple.svg')} alt="Apple App Store" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=app.speedweaver.co.uk" target="_blank" rel="noopener norefferer">
                                        <img src={require('../../images/icons/google.svg')} alt="Google Play Store" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3>News &amp; Tutorials</h3>
                            <NavLink to="/news">News</NavLink>
                            <NavLink to="/tutorials">Tutorials</NavLink>
                        </div>
                    </div>

                    <div className="newsletter">
                        <h3>Sign up to our newsletter</h3>
                        <p>Receive the latest news and updates from the Speedweaver™ team sent direct to your inbox.</p>
                        <Mailchimp
                            action='https://speedweaver.us19.list-manage.com/subscribe/post?u=f5252001e75419dba07479e1a&amp;id=3d53dcb957'
                            fields={[
                                {
                                    name: 'EMAIL',
                                    placeholder: 'Email Address',
                                    type: 'email',
                                    required: true
                                }
                            ]}
                        />
                        <p>Copyright 2021 IDX Digital Limited. All rights reserved.</p>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;
